
import { DropdownOptionObject } from "@/shared/components/Forms/InputDropdown.vue";
import PageLayout from "@/shared/components/Layouts/PageLayout.vue";
import OrderShippingDetailForm, {
  OrderShippingDetailData,
} from "@/shared/components/Orders/OrderShippingDetailForm.vue";
import { useOrder } from "@/shared/composables/useOrder";
import { useOrderShippingDetails } from "@/shared/composables/useOrderShippingDetails";
import { useRedirectHelper } from "@/shared/composables/useRedirectHelper";
import { PlusOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent, reactive, ref, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import FormGroup from "@/shared/components/Forms/FormGroup.vue";
import Card from "@/shared/components/Card.vue";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const route = useRoute();

    const { redirectToOrderDetails } = useRedirectHelper();

    const { order, orderLoading, selectedPerformers } = useOrder({
      orderId: ref(String(route.params.orderId)),
    });

    const formState = reactive({
      shippingDetails: [] as OrderShippingDetailData[],
    });

    const orderId = computed(() => order.value?.id);
    const performerOptions = computed<DropdownOptionObject[]>(() =>
      selectedPerformers.value.map((performer) => ({
        value: performer.id,
        label: performer.performerProfile?.nickname ?? "",
      }))
    );

    const {
      orderShippingDetails,
      orderShippingDetailsLoading,
      saveOrderShippingDetail,
      saveOrderShippingDetailLoading,
      removeOrderShippingDetail,
      removeOrderShippingDetailLoading,
      confirmOrderShippingDetails,
      confirmOrderShippingDetailsLoading,
    } = useOrderShippingDetails(orderId);

    const handleAdd = async () => {
      formState.shippingDetails.push({
        id: null,
        performerId: null,
        courier: "",
        trackingNumberOrLink: "",
        notes: "",
        fileList: [],
      });
    };

    // Handlers
    const handleDelete = async (index: number | null) => {
      if (typeof index === "number" && index >= 0) {
        formState.shippingDetails.splice(index, 1);
      }
    };

    const handleSubmit = async () => {
      // Delete missing IDs
      const remainingFormIds = formState.shippingDetails.map((item) => item.id);
      const toDeleteIds = orderShippingDetails.value
        .filter((item) => !remainingFormIds.includes(item!.id))
        .map((item) => item!.id);
      toDeleteIds.forEach(async (id) => {
        await removeOrderShippingDetail({
          shippingDetailId: id,
        });
      });

      // Update/save new ones
      formState.shippingDetails.forEach(async (item) => {
        await saveOrderShippingDetail(
          {
            shippingDetailId: item.id,
            performerId: item.performerId,
            courier: item.courier,
            trackingNumberOrLink: item.trackingNumberOrLink,
            notes: item.notes,
          },
          item.fileList.at(0)?.originFileObj
        );
      });
      await confirmOrderShippingDetails({ id: orderId.value ?? "" });

      await redirectToOrderDetails({ orderId: orderId.value ?? "" });
    };

    // Pre-fill existing data
    watchEffect(() => {
      if (orderShippingDetails.value) {
        // Reset first, before pre-fill
        formState.shippingDetails = [];

        orderShippingDetails.value.forEach((details) => {
          formState.shippingDetails.push({
            id: details?.id || null,
            performerId: details?.performer?.id || null,
            courier: details?.courier || "",
            trackingNumberOrLink: details?.trackingNumberOrLink || "",
            notes: details?.notes || "",
            fileList: [],
            currentFileUrl: details?.file || "",
          });
        });
      }
    });

    return {
      t,
      order,
      orderId,
      formState,

      orderLoading: orderLoading,
      selectedPerformers,
      performerOptions,

      orderShippingDetails,
      orderShippingDetailsLoading,

      saveOrderShippingDetailLoading,
      confirmOrderShippingDetailsLoading,
      removeOrderShippingDetailLoading,

      handleAdd,
      handleDelete,
      handleSubmit,

      pageLoading: computed(
        () => orderLoading.value || orderShippingDetailsLoading.value
      ),
      submitLoading: computed(
        () =>
          saveOrderShippingDetailLoading.value ||
          confirmOrderShippingDetailsLoading.value ||
          removeOrderShippingDetailLoading.value
      ),
    };
  },
  components: {
    PageLayout,
    OrderShippingDetailForm,
    PlusOutlined,
    FormGroup,
    Card,
  },
});
