
import InputDropdown, {
  DropdownOptionObject,
} from "@/shared/components/Forms/InputDropdown.vue";
import InputText from "@/shared/components/Forms/InputText.vue";
import InputTextArea from "@/shared/components/Forms/InputTextArea.vue";
import InputUpload from "@/shared/components/Forms/InputUpload.vue";
import AppIcon from "@/shared/components/Icons/AppIcon.vue";
import { PropType, computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import type { UploadFile } from "ant-design-vue";
import { useVModel } from "vue-composable";
import { makeRequiredRule } from "@/shared/utils/validators/commonValidators";

export type OrderShippingDetailData = {
  id: string | null;
  performerId: string | null;
  courier: string;
  trackingNumberOrLink: string;
  notes: string;
  fileList: UploadFile[];
  // Extra fields, not for formState
  currentFileUrl?: string;
};

export default defineComponent({
  props: {
    formState: {
      type: Object as PropType<OrderShippingDetailData>,
      required: true,
    },
    performerOptions: Array as PropType<DropdownOptionObject[]>,
    index: Number,
    loading: Boolean,
  },
  setup(props) {
    const { t } = useI18n();

    const innerFormState = useVModel(props, "formState");

    return {
      t,
      makeRequiredRule,
      innerFormState,
      performerOptionsWithOther: computed<DropdownOptionObject[]>(() => [
        ...(props.performerOptions ?? []),
        { label: "Other", value: null },
      ]),
    };
  },
  components: {
    InputText,
    InputTextArea,
    InputDropdown,
    AppIcon,
    InputUpload,
  },
});
