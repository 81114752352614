import gql from "graphql-tag";

export const removeOrderShippingDetailGql = gql`
  mutation RemoveOrderShippingDetail($input: RemoveOrderShippingDetailInput!) {
    removeOrderShippingDetail(input: $input) {
      __typename
      ... on ResponseErrors {
        errors {
          __typename
          code
          message
          ... on InvalidInputError {
            field
          }
        }
      }
      ... on GenericSuccess {
        success
      }
    }
  }
`;
