import gql from "graphql-tag";

export const saveOrderShippingDetailGql = gql`
  mutation SaveOrderShippingDetail(
    $input: SaveOrderShippingDetailInput!
    $file: Upload
  ) {
    saveOrderShippingDetail(input: $input, file: $file) {
      __typename
      ... on ResponseErrors {
        errors {
          __typename
          code
          message
          ... on InvalidInputError {
            field
          }
        }
      }
      ... on OrderShippingDetail {
        id
        order {
          id
        }
        performer {
          id
        }
        courier
        trackingNumberOrLink
        notes
        file
      }
    }
  }
`;
