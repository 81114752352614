import { useCustomMutation } from "@/api/graphqlClient/useCustomMutation";
import { orderShippingDetailsGql } from "@/api/order/orderShippingDetails";
import {
  OrderShippingDetails,
  OrderShippingDetailsVariables,
  OrderShippingDetails_orderShippingDetails_OrderShippingDetailResults,
} from "@/api/order/__generated__/OrderShippingDetails";
import { saveOrderShippingDetailGql } from "@/api/order/saveOrderShippingDetail";
import {
  SaveOrderShippingDetail,
  SaveOrderShippingDetailVariables,
  SaveOrderShippingDetail_saveOrderShippingDetail_OrderShippingDetail,
} from "@/api/order/__generated__/SaveOrderShippingDetail";

import { removeOrderShippingDetailGql } from "@/api/order/removeOrderShippingDetail";
import {
  RemoveOrderShippingDetail,
  RemoveOrderShippingDetailVariables,
  RemoveOrderShippingDetail_removeOrderShippingDetail_GenericSuccess,
} from "@/api/order/__generated__/RemoveOrderShippingDetail";
import { confirmOrderShippingDetailsGql } from "@/api/order/confirmOrderShippingDetails";
import {
  ConfirmOrderShippingDetails,
  ConfirmOrderShippingDetailsVariables,
  ConfirmOrderShippingDetails_confirmOrderShippingDetails_GenericSuccess,
} from "@/api/order/__generated__/ConfirmOrderShippingDetails";
import { Ref, computed } from "vue";
import {
  SaveOrderShippingDetailInput,
  RemoveOrderShippingDetailInput,
  IdInput,
} from "__generated__/globalTypes";
import { parseGqlResponse } from "@/shared/utils/graphql/responseParser";
import { useQuery } from "@vue/apollo-composable";

export const useOrderShippingDetails = (orderId: Ref<string | undefined>) => {
  // === Order Shipping Details (List) ===
  const {
    result: orderShippingDetailsResult,
    loading: orderShippingDetailsLoading,
  } = useQuery<OrderShippingDetails, OrderShippingDetailsVariables>(
    orderShippingDetailsGql,
    () => ({ input: { orderId: orderId.value } }),
    () => ({ enabled: !!orderId.value })
  );

  const orderShippingDetails = computed(() => {
    const parsedResponse =
      parseGqlResponse<OrderShippingDetails_orderShippingDetails_OrderShippingDetailResults>(
        "OrderShippingDetailResults",
        orderShippingDetailsResult.value
      );

    // Sort to prevent changing order in UI
    return [...(parsedResponse.data?.results ?? [])].sort(
      (a, b) => Number(a?.id) - Number(b?.id)
    );
  });

  // === Save Order Shipping Detail ===
  const {
    mutate: saveOrderShippingDetailMutate,
    loading: saveOrderShippingDetailLoading,
  } = useCustomMutation<
    SaveOrderShippingDetail,
    SaveOrderShippingDetailVariables
  >(saveOrderShippingDetailGql);

  const saveOrderShippingDetail = async (
    input: SaveOrderShippingDetailInput,
    file?: File
  ) => {
    const response = await saveOrderShippingDetailMutate({
      input: { orderId: orderId.value, ...input },
      file: file,
    });

    const parsedResponse =
      parseGqlResponse<SaveOrderShippingDetail_saveOrderShippingDetail_OrderShippingDetail>(
        "OrderShippingDetail",
        response
      );

    return parsedResponse.data;
  };

  // === Remove Order Shipping Detail ===
  const {
    mutate: removeOrderShippingDetailMutate,
    loading: removeOrderShippingDetailLoading,
  } = useCustomMutation<
    RemoveOrderShippingDetail,
    RemoveOrderShippingDetailVariables
  >(removeOrderShippingDetailGql);

  const removeOrderShippingDetail = async (
    input: RemoveOrderShippingDetailInput
  ) => {
    const response = await removeOrderShippingDetailMutate({
      input: { orderId: orderId.value, ...input },
    });

    const parsedResponse =
      parseGqlResponse<RemoveOrderShippingDetail_removeOrderShippingDetail_GenericSuccess>(
        "GenericSuccess",
        response
      );

    return parsedResponse.data?.success;
  };

  // === Confirm Order Shipping Details ===
  const {
    mutate: confirmOrderShippingDetailsMutate,
    loading: confirmOrderShippingDetailsLoading,
  } = useCustomMutation<
    ConfirmOrderShippingDetails,
    ConfirmOrderShippingDetailsVariables
  >(confirmOrderShippingDetailsGql, {
    refetchQueries: () => [
      {
        query: orderShippingDetailsGql,
        variables: <OrderShippingDetailsVariables>{
          input: { orderId: orderId.value },
        },
      },
    ],
  });

  const confirmOrderShippingDetails = async (input?: IdInput) => {
    const response = await confirmOrderShippingDetailsMutate({
      input: { id: orderId.value ?? "", ...input },
    });

    const parsedResponse =
      parseGqlResponse<ConfirmOrderShippingDetails_confirmOrderShippingDetails_GenericSuccess>(
        "GenericSuccess",
        response
      );

    return parsedResponse.data?.success;
  };

  return {
    orderShippingDetails,
    orderShippingDetailsLoading,

    saveOrderShippingDetail,
    saveOrderShippingDetailLoading,

    removeOrderShippingDetail,
    removeOrderShippingDetailLoading,

    confirmOrderShippingDetails,
    confirmOrderShippingDetailsLoading,
  };
};
