import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "order-shipping-detail-form" }
const _hoisted_2 = { class: "flex flex-wrap justify-between items-start mb-3" }
const _hoisted_3 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_AppIcon = _resolveComponent("AppIcon")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_InputDropdown = _resolveComponent("InputDropdown")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputUpload = _resolveComponent("InputUpload")!
  const _component_InputTextArea = _resolveComponent("InputTextArea")!
  const _component_a_row = _resolveComponent("a-row")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_a_typography_text, { class: "order-shipping-detail-form__index" }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.t("#{id}", {
            id: ((_ctx.index ?? 0) + 1)?.toString().padStart(3, "0"),
          })), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_a_button, {
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('delete', _ctx.index))),
        class: "p-2 h-auto leading-none",
        disabled: _ctx.loading
      }, {
        default: _withCtx(() => [
          _createVNode(_component_AppIcon, {
            name: "trashBlue",
            class: "h-4 w-4"
          })
        ]),
        _: 1
      }, 8, ["disabled"])
    ]),
    _createVNode(_component_a_row, { gutter: [18, 22] }, {
      default: _withCtx(() => [
        _createVNode(_component_a_col, {
          span: 24,
          lg: 12
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputDropdown, {
              name: ['shippingDetails', _ctx.index, 'performerId'],
              value: _ctx.innerFormState.performerId,
              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.innerFormState.performerId) = $event)),
              label: _ctx.t('Model Creator / Recipient of your Product *'),
              options: _ctx.performerOptionsWithOther,
              disabled: _ctx.loading
            }, null, 8, ["name", "value", "label", "options", "disabled"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          span: 24,
          lg: 12
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              name: ['shippingDetails', _ctx.index, 'courier'],
              value: _ctx.innerFormState.courier,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.innerFormState.courier) = $event)),
              label: _ctx.t('Courier *'),
              placeholder: _ctx.t('e.g. J&T Express, GogoXpress, LBC'),
              rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
            }, null, 8, ["name", "value", "label", "placeholder", "rules"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          span: 24,
          lg: 12
        }, {
          default: _withCtx(() => [
            _createVNode(_component_InputText, {
              name: ['shippingDetails', _ctx.index, 'trackingNumberOrLink'],
              value: _ctx.innerFormState.trackingNumberOrLink,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.innerFormState.trackingNumberOrLink) = $event)),
              label: _ctx.t('Tracking Number/Link *'),
              placeholder: _ctx.t('Number or Link'),
              rules: [_ctx.makeRequiredRule(_ctx.t('This field is required.'))]
            }, null, 8, ["name", "value", "label", "placeholder", "rules"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, {
          span: 24,
          lg: 12
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_typography_text, { class: "ant-form-item-label block" }, {
              default: _withCtx(() => [
                _createElementVNode("label", null, _toDisplayString(_ctx.t("Additional File")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_InputUpload, {
              name: "fileList",
              "trigger-label": 
            !_ctx.innerFormState.currentFileUrl
              ? _ctx.t('Click to Upload')
              : _ctx.t('Update File')
          ,
              value: _ctx.innerFormState.fileList,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.innerFormState.fileList) = $event)),
              disabled: _ctx.loading,
              loading: _ctx.loading,
              "max-count": 1,
              "max-file-size-in-mb": 10,
              class: "order-shipping-detail-form__upload mb-2"
            }, null, 8, ["trigger-label", "value", "disabled", "loading"]),
            (_ctx.innerFormState.currentFileUrl)
              ? (_openBlock(), _createElementBlock("a", {
                  key: 0,
                  href: _ctx.innerFormState.currentFileUrl,
                  download: "",
                  class: "block whitespace-nowrap overflow-hidden overflow-ellipsis"
                }, _toDisplayString(_ctx.innerFormState.currentFileUrl), 9, _hoisted_3))
              : _createCommentVNode("", true)
          ]),
          _: 1
        }),
        _createVNode(_component_a_col, { span: 24 }, {
          default: _withCtx(() => [
            _createVNode(_component_InputTextArea, {
              name: ['shippingDetails', _ctx.index, 'notes'],
              value: _ctx.innerFormState.notes,
              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.innerFormState.notes) = $event)),
              label: _ctx.t('Notes'),
              placeholder: 
            _ctx.t('Additional notes and instructions for receiving the item/s.')
          
            }, null, 8, ["name", "value", "label", "placeholder"])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}