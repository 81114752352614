import gql from "graphql-tag";

export const orderShippingDetailsGql = gql`
  query OrderShippingDetails($input: OrderShippingDetailsInput!) {
    orderShippingDetails(input: $input) {
      __typename
      ... on ResponseErrors {
        errors {
          __typename
          code
          message
          ... on InvalidInputError {
            field
          }
        }
      }
      ... on OrderShippingDetailResults {
        __typename
        results {
          id
          performer {
            id
            nickname
          }
          courier
          notes
          trackingNumberOrLink
          file
        }
      }
    }
  }
`;
