import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = {
  key: 1,
  class: "order-shipping-details__empty py-24"
}
const _hoisted_4 = { class: "text-right mt-16" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_skeleton = _resolveComponent("a-skeleton")!
  const _component_a_typography_title = _resolveComponent("a-typography-title")!
  const _component_a_typography_paragraph = _resolveComponent("a-typography-paragraph")!
  const _component_a_typography_text = _resolveComponent("a-typography-text")!
  const _component_Card = _resolveComponent("Card")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_OrderShippingDetailForm = _resolveComponent("OrderShippingDetailForm")!
  const _component_a_divider = _resolveComponent("a-divider")!
  const _component_PlusOutlined = _resolveComponent("PlusOutlined")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_FormGroup = _resolveComponent("FormGroup")!
  const _component_PageLayout = _resolveComponent("PageLayout")!

  return (_openBlock(), _createBlock(_component_PageLayout, {
    "back-button": "",
    onOnBack: _ctx.$router.back,
    class: "order-shipping-details mb-32"
  }, {
    default: _withCtx(() => [
      (_ctx.pageLoading)
        ? (_openBlock(), _createBlock(_component_a_skeleton, {
            key: 0,
            active: ""
          }))
        : (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(_component_FormGroup, {
              model: _ctx.formState,
              onFinish: _ctx.handleSubmit,
              loading: _ctx.submitLoading
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_typography_title, {
                  level: 5,
                  class: "order-shipping-details__order-id"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t("Order #{id}", {
              id: _ctx.orderId,
            })), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, { gutter: [32, 64] }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, {
                      span: 24,
                      xl: 10
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_typography_title, {
                          level: 5,
                          class: "m-0 mb-2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("STEP 1")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_typography_title, {
                          level: 4,
                          class: "m-0 mb-10"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Send Your Products To The Creator")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_typography_title, {
                              level: 3,
                              class: "m-0 mb-5"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Shipping Information")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_typography_paragraph, null, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t(
                    "You may now send your products to us. Here are the shipping info on the model creators chosen for your brand:"
                  )), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_typography_text, {
                              innerHTML: _ctx.order?.shipmentInstructions
                            }, null, 8, ["innerHTML"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, {
                      span: 24,
                      xl: 14
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_typography_title, {
                          level: 5,
                          class: "m-0 mb-2"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("STEP 2")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_a_typography_title, {
                          level: 4,
                          class: "m-0 mb-10"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.t("Send Your Tracking Details")), 1)
                          ]),
                          _: 1
                        }),
                        _createVNode(_component_Card, null, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_typography_title, {
                              level: 3,
                              class: "m-0 mb-5"
                            }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t("Tracking Details Form")), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_a_typography_paragraph, { class: "mb-8" }, {
                              default: _withCtx(() => [
                                _createTextVNode(_toDisplayString(_ctx.t(
                    "We kindly request that you complete this form by providing us with essential and correct details regarding the shipping of your products to creator of your choosing."
                  )), 1)
                              ]),
                              _: 1
                            }),
                            (_ctx.formState.shippingDetails.length)
                              ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                                  _createVNode(_TransitionGroup, { name: "fade" }, {
                                    default: _withCtx(() => [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formState.shippingDetails, (state, index) => {
                                        return (_openBlock(), _createBlock(_component_OrderShippingDetailForm, {
                                          "form-state": state,
                                          key: index,
                                          index: index,
                                          loading: 
                      _ctx.saveOrderShippingDetailLoading ||
                      _ctx.removeOrderShippingDetailLoading
                    ,
                                          "performer-options": _ctx.performerOptions,
                                          onDelete: _ctx.handleDelete,
                                          class: "mb-14"
                                        }, null, 8, ["form-state", "index", "loading", "performer-options", "onDelete"]))
                                      }), 128))
                                    ]),
                                    _: 1
                                  })
                                ]))
                              : (_openBlock(), _createElementBlock("div", _hoisted_3, [
                                  _createVNode(_component_a_typography_title, {
                                    level: 2,
                                    class: "text-center"
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t("No shipping details.")), 1)
                                    ]),
                                    _: 1
                                  }),
                                  _createVNode(_component_a_typography_paragraph, { class: "text-center" }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(_ctx.t("Kindly add one using the button below.")), 1)
                                    ]),
                                    _: 1
                                  })
                                ])),
                            _createVNode(_component_a_divider),
                            _createVNode(_component_a_button, {
                              type: "dashed",
                              onClick: _ctx.handleAdd,
                              loading: _ctx.saveOrderShippingDetailLoading,
                              size: "large",
                              block: "",
                              class: "mt-3"
                            }, {
                              icon: _withCtx(() => [
                                _createVNode(_component_PlusOutlined)
                              ]),
                              default: _withCtx(() => [
                                _createTextVNode(" " + _toDisplayString(_ctx.t("Add Shipping Info")), 1)
                              ]),
                              _: 1
                            }, 8, ["onClick", "loading"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createElementVNode("div", _hoisted_4, [
                  _createVNode(_component_a_button, {
                    type: "primary",
                    "html-type": "submit",
                    loading: _ctx.confirmOrderShippingDetailsLoading,
                    size: "large",
                    class: "order-shipping-details__save-btn"
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.t("Save and Exit")), 1)
                    ]),
                    _: 1
                  }, 8, ["loading"])
                ])
              ]),
              _: 1
            }, 8, ["model", "onFinish", "loading"])
          ]))
    ]),
    _: 1
  }, 8, ["onOnBack"]))
}